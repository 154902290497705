
import { Options, Vue } from 'vue-class-component';

import Clipboard from 'clipboard';

import success from '@/icons/service/Check.vue';
import error from '@/icons/service/Close.vue';

enum CB_STATUS {
  default = 'default',
  success = 'success',
  error = 'error',
}

@Options({
  components: {
    success,
    error,
  },
  props: {
    title: [String, Number],
    value: [String, Number],
    hidden: Boolean,
  },
})
export default class ClipboardCredentials extends Vue {
  declare $refs: { copy: HTMLButtonElement };

  declare $props: {
    title: string | number;
    value: string | number;
    hidden: boolean;
  }

  public CB_STATUS = CB_STATUS;

  public clipboard: Clipboard | null = null;

  public status: CB_STATUS = CB_STATUS.default;

  public showValue = false;

  private changeStatus(status: CB_STATUS): void {
    this.status = status;

    setTimeout(() => {
      this.status = CB_STATUS.default;
    }, 1500);
  }

  public initClipboard(): void {
    this.clipboard = new Clipboard(this.$refs.copy, {
      text: (): string => String(this.$props.value),
    });

    this.clipboard.on('success', (e: Clipboard.Event) => {
      this.changeStatus(CB_STATUS.success);

      e.clearSelection();
    });

    this.clipboard.on('error', () => {
      this.changeStatus(CB_STATUS.error);
    });
  }

  mounted(): void {
    this.initClipboard();
  }

  unmounted(): void {
    if (this.clipboard !== null) this.clipboard.destroy();
  }
}
